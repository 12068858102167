import { graphql } from "gatsby";
import React from "react";
import { InterviewList, Layout, Seo } from "../components";
import styled from "@emotion/styled";
import { mq } from "../styles/GlobalStyles";

interface YamlData {
  allInterviewYaml: {
    edges: [
      {
        node: {
          site: {
            members: string[];
            title: string;
            url: string;
          };
        };
      }
    ];
    totalCount: number;
  };
}

const H1 = styled.h1(
  mq({
    fontSize: ["2.5em", "3.5em"],
    color: "#2E3C4D",
    marginBottom: "12px",
    fontFamily: `"Manrope", sans-serif`,
    span: {
      color: "#999999",
      display: "inline-block",
      marginLeft: "16px",
      fontSize: "0.35em",
    },
  })
);

const Interviews = ({ _, data }: { _: any; data: YamlData }) => {
  /* const { context } = pageContext; */
  const { edges, totalCount } = data.allInterviewYaml;
  return (
    <Layout>
      <>
        <Seo title="インタビュー" />
        <H1>
          Interview<span>{totalCount} articles</span>
        </H1>
        <InterviewList lists={edges} />
      </>
    </Layout>
  );
};

export default Interviews;

export const pageQuery = graphql`
  query {
    allInterviewYaml {
      totalCount
      edges {
        node {
          id
          site {
            members
            title
            url
            hero {
              childImageSharp {
                gatsbyImageData(width: 337, height: 190, layout: CONSTRAINED)
              }
            }
            heroAlt
          }
        }
      }
    }
  }
`;
